<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-top card-top-primary">
          <div class="card-header"><vb-headers-card-header :data="{ title: 'Activities' }" /></div>
          <div class="card-header"><vb-headers-heading :data="{ title: 'Todays' }" /></div>
          <div class="card-body"><vb-widgets-lists-16 /></div>
          <div class="card-header"><vb-headers-heading :data="{ title: 'Yesterdays' }" /></div>
          <div class="card-body"><vb-widgets-lists-16 /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VbHeadersCardHeader from '@/@vb/widgets/Headers/CardHeader'
import VbHeadersHeading from '@/@vb/widgets/Headers/Heading'
import VbWidgetsLists16 from '@/@vb/widgets/WidgetsLists/16'

export default {
  name: 'VbActivity',
  components: {
    VbHeadersCardHeader,
    VbHeadersHeading,
    VbWidgetsLists16,
  },
}
</script>
